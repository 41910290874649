import {Stories} from './Stories';
import React, {useEffect, useState} from 'react';
import {
    useRouteMatch
} from 'react-router-dom';
import {Header} from "./Header";

type PaginatedStoriesProps = {
    byLink?: boolean;
    byTag?: boolean;
    link?: string;
    tag?: string;
};

export const PaginatedStories: React.FC<PaginatedStoriesProps> = (props) => {
    const matchTag = useRouteMatch({
        path: "/tag/:tag",
        strict: true,
        sensitive: true
    });
    const matchLink = useRouteMatch({
        path: "/story/:storyLink",
        strict: true,
        sensitive: true
    });
    const matchNoveletteLink = useRouteMatch({
        path: "/novelette/:storyLink",
        strict: true,
        sensitive: true
    });
    const nr_of_stories = 200;
    const [storiesParsed, setStoriesParsed] = useState([]);

    useEffect(() => {
        let storyLink = undefined;
        let tag = undefined;
        if (!!props.byLink && matchLink && matchLink.params !== null && matchLink.params.hasOwnProperty('storyLink')) {
            storyLink = matchLink.params.storyLink;
            getData(storyLink, undefined);
        } else if (!!props.byLink && matchNoveletteLink && matchNoveletteLink.params !== null && matchNoveletteLink.params.hasOwnProperty('storyLink')) {
            storyLink = matchNoveletteLink.params.storyLink;
            getData(storyLink, undefined, 'novelette');
        } else if (!!props.byTag && matchTag && matchTag.params !== null && matchTag.params.hasOwnProperty('tag')) {
            tag = matchTag.params.tag;
            getData(undefined, tag);
        } else {
            getData(undefined, undefined);
        }
    }, [props]);

    const getData = (storyLink: ?string, tag: ?string, source?: string) => {
           fetch(!!source && source === 'novelette' ? '/star_dregs_p_a_a_a.json' : '/stories_en.json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then(function (response) {
            return response.json();
        }).then(function (response) {
            const allStories = response.slice(0, nr_of_stories)
                .map(story => {
                    return {...story, type: !!source ? source : 'story'};
                })
            if (!!storyLink) {
                const singleStory = allStories.filter(item => item.link === storyLink);
                setStoriesParsed(singleStory);
            } else if (!!tag) {
                const stories = allStories.filter(item => item.tags.indexOf(tag) !== -1);
                setStoriesParsed(stories);
            } else {
                setStoriesParsed(allStories);
            }
        });
    };

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);

    return (
        <>
        <Header />
            <div className="container d-block mt-100 Container">
                <div className="row text-justify d-flex justify-content-center">
                    {storiesParsed.length &&
                    <Stories items={storiesParsed} />
                    }
                </div>
            </div>
        </>
    );
}