import './App.css';
import React from 'react';
import { Tags } from './Tags';
import { StoryType } from './storyType';
import { Link } from "react-router-dom";
import {getRandomColor} from "./utils";

export const Story: React.FC = (props: StoryType) => {
    // const metas = [
    //     {name: "og:title", content: props.title},
    //     {name: "og:url", content: props.link},
    //     {name: "article:published_time", content: props.pubDate},
    //     {name: "og:image", content: props.pic},
    //     {name: "og:description", content: props.title}
    // ];
    //
    // metas.forEach(tag => {
    //     if (document.querySelector("[property='"+tag.name+"']") !== null) {
    //         document.querySelector("[property='"+tag.name+"']").remove();
    //     }
    //     const meta = document.createElement('meta');
    //     meta.setAttribute('property', tag.name);
    //     meta.content = tag.content;
    //     document.getElementsByTagName('head')[0].appendChild(meta);
    // });

    return (
        <>
            <div className="col col-sm-11 col-md-10 col-xl-8 StoryContent">
                <Link to={'/' + props.type + '/'+props.link}>
                    <div className={'StoryTitle'} style={{color: getRandomColor()}}>{props.title}</div>
                </Link>
                <div className={'pubDate'}>({(props.pubDate)})</div>
                {
                    !!props.pic && props.pic !== "" &&
                        <img className="img-responsive StoryImage" src={'/pics/' + props.pic} alt={props.title} />
                }
                {
                    !!props.triggerWarnings && (<>
                        <h5 className='text-align-center'>Trigger warnings:</h5>
                    <Tags items={props.triggerWarnings} className="mb-1" />
                        </>)
                }
            <div dangerouslySetInnerHTML={{
                    __html: props.content,
                }}>
            </div>
            <h5 className='text-align-center'>Tags:</h5>
            <Tags items={props.tags} className="mb-1" />
            </div>
        </>
    );
}

export default Story;
