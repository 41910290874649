import {Link} from "react-router-dom";
import React from "react";

export const Header: React.FC = () => {
    return    (<div>
        <header className="AppHeader">
            <span>sab, these are her stories: </span>
            <br/>
            <Link to={'/'}>
                jumbled tags
            </Link>
            |
            <Link to={'/all'}>
                list
            </Link>
            |
            <Link to={'/story/i-am-the-steampunk-wizards'}>
                latest
            </Link>
            |
            <Link to={'/novelette/star-dregs-p-a-a-a'}>
                star dregs p. a. a. a.
            </Link>
        </header>
    </div>    )
}
