import './App.css';
import React from 'react';
import {Link} from "react-router-dom";

export type TagProps = {
    text: string;
    color?: string;
};

export const Tag: React.FC = (props: TagProps) => {
    const color = props.color ? props.color : 'black';
    return (
        <>
            <Link to={'/tag/'+props.text}>
                <span className={'StoryTag'} style={{backgroundColor: color, color: "white"}}>{props.text}</span>
            </Link>
        </>
    );
}

export default Tag;
