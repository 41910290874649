import './App.css';
import React, {useEffect, useState} from 'react';
import {Tag} from './Tag';
import jq from 'jq-in-the-browser';
import {Header} from "./Header";
import {getRandomColor} from "./utils";

export const MainPage: React.FC = (props) => {
    const [tags, setTags] = useState(undefined);

    const getTags = () => {
        let jsonTags = [];
        fetch('/stories_en.json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then(function (response) {
            return response.json();
        }).then(function (response) {
            const query = jq('[.[] | .tags]');
            const result = query(response);
            result.forEach(tagArray => {
                    const union = [...jsonTags, ...tagArray];
                    jsonTags = union.filter((item, index) => union.indexOf(item) === index);
                }
            );

            const shuffle = require('knuth-shuffle').knuthShuffle;
            const tags = shuffle(jsonTags);
            setTags(tags);
        });
    };

        useEffect(() => {
            getTags();
        }, []);


        return <>
            <Header />
            {
                !!tags &&
                <div className={'App'} style={{textAlign: 'center', marginTop: '10px'}}>
                    {tags.map((item: string) => (
                            <Tag
                                color={getRandomColor()}
                                key={item}
                                text={item}/>
                                            ))}
                </div>

            }
        </>;
    }

    export default MainPage;
