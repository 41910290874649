import './App.css';
import React from 'react';
import { Tag } from './Tag';

export type TagsProps = {
    items: string[];
};

export const Tags: React.FC = (props: TagsProps) => {
    return <>
        <div className={'Tags'}>
        {props.items.map((item: string) => (
        <Tag key={item} text={item} />
    ))}
        </div>
    </>;
}

export default Tags;
