import React from 'react';
import './App.css';
import { PaginatedStories } from './PaginatedStories';
import { MainPage } from './MainPage';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

export const App: React.FC = (props) => {
    return (
        <Router>
                <Switch>
                    {["/story/:storyLink"].map(path => (
                        <Route
                            key={path}
                            exact
                            path={path}
                            render={() => <PaginatedStories key={path} byLink={true} />}
                        />
                    ))}
                    {["/novelette/:storyLink"].map(path => (
                        <Route
                            key={path}
                            exact
                            path={path}
                            render={() => <PaginatedStories key={path} byLink={true} />}
                        />
                    ))}
                    {["/tag/:tag"].map(path => (
                        <Route
                            key={path}
                            exact
                            path={path}
                            render={() => <PaginatedStories key={path} byTag={true} />}
                        />
                    ))}
                    {["/all"].map(path => (
                        <Route
                            key={path}
                            exact
                            path={path}
                            render={() => <PaginatedStories key={'all'} byLink={false} byTag={false} />}
                        />
                    ))}
                    <Route key={'/'} component={MainPage} />
                </Switch>

                    {props.children}
        </Router>
    );
}

export default App;
