import { Story } from './Story';
import React from 'react';

type StoriesType = {
    items: Story[];
};

export const Stories: React.FC<StoriesType> = (props) => {
    return <>
        {props.items.map((item, index) => (
            <Story key={item.link} {...item} />
        ))}
    </>;
}

